import React from "react";
import styles from "./Logo.module.css";

const Logo = () => {
  return (
    <a className={styles.link} href="/">
      <img className={styles.logo} src={"/new_logo.png"} />
      {/* <div className={styles.container}>
        <p className={styles.title}>A</p>
      </div> */}
    </a>
  );
};

export default Logo;
