import React from "react";
import Switch from "react-switch";

const SwitchComponent = ({ handleChange, checked }) => {
  return (
    <span
      style={{
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
        borderRadius: "13px",
      }}
    >
      <Switch
        onChange={handleChange}
        checked={checked}
        offColor="#ffffff"
        onColor="#ffffff"
        onHandleColor="#E48720"
        offHandleColor="#26A48E"
        handleDiameter={20}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow="0px 0px 10px rgba(0, 0, 0, 0.25)"
        activeBoxShadow="0px 0px 10px rgba(0, 0, 0, 0.25)"
        height={30}
        width={70}
        id="material-switch"
      />
    </span>
  );
};

export default SwitchComponent;
