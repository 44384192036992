import React from "react";
import styles from "./InfoContainer.module.css";

const InfoContainer = ({ data, location }) => {
  const days = {
    BERKELEY: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    OAKLAND: ["Tue", "Wed", "Thu", "Fri", "Sat"],
  };

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <p className={styles.fieldName}>Location</p>
        <span className={styles.fieldValue}>
          <p>{data.location}</p>
        </span>
      </div>
      <div className={styles.info}>
        <p className={styles.fieldName}>Call</p>
        <span className={styles.fieldValue}>
          <p>{data.call}</p>
        </span>
      </div>
      <div className={styles.info}>
        <p className={styles.fieldName}>Email</p>
        <span className={styles.fieldValue}>
          <p>{data.email}</p>
        </span>
      </div>
      <div className={styles.info}>
        <p className={styles.fieldName}>Hours of Operation </p>
        <span className={styles.fieldValue}>
          <p>
            {data.hrsOfOperation.map((item, index) => (
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 4fr",
                  width: "100%",
                }}
              >
                <span>{days[location ? location : "BERKELEY"][index]}</span>
                <span key={index}>: {item}</span>
              </div>
            ))}
          </p>
        </span>
      </div>
    </div>
  );
};

export default InfoContainer;
