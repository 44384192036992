export const getGallery = async () => {
  try {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/api/story`)
      .then((response) => response.json())
      .then((data) => data);
    if (data.success) {
      return { data: data.data };
    } else {
      return { data: [] };
    }
  } catch (error) {
    console.log(error);
    return { data: [] };
  }
};

export const addEmail = async (email) => {
  return fetch(`${process.env.REACT_APP_API_URL}/api/email`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email }),
  })
    .then((response) => response.json())
    .then((data) => data);
};
