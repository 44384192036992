import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import styles from "./OurServices.module.css";
import { RiDoubleQuotesL, RiDoubleQuotesR } from "react-icons/ri";
import { MdArrowBackIos, MdArrowBackIosNew, MdArrowForwardIos } from "react-icons/md";

const SliderComp = ({ reviews }) => {
  const [currentReview, setCurrentReview] = useState(0);

  const handlePrevious = () => {
    setCurrentReview((currentReview - 1 + reviews.length) % reviews.length);
  };

  const handleNext = () => {
    setCurrentReview((currentReview + 1) % reviews.length);
  };

  return (
    <div className={styles.slider}>
      <MdArrowBackIosNew className={styles.arrow} size={30} onClick={handlePrevious} />
      <AnimatePresence initial={false} mode="wait">
        <motion.div
          key={currentReview}
          className={styles.secondary}
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 50 }}
          transition={{ duration: 0.5 }}>
          <div className={styles.secondaryContent}>
            <div className={styles.imgContainer}>
              <img alt="" src={reviews[currentReview].image} />
            </div>
            <div>
              <p>
                <RiDoubleQuotesL size={20} style={{ marginRight: 10 }} />
                {reviews[currentReview].desc}
                <RiDoubleQuotesR size={20} style={{ marginLeft: 10 }} />
              </p>
              <div>
                <p className={styles.review}>- {reviews[currentReview].name}</p>
                <p className={styles.review}>
                  {reviews[currentReview].location}
                </p>
              </div>
            </div>
          </div>
        </motion.div>
      </AnimatePresence>
      <MdArrowForwardIos size={30} className={styles.arrow} onClick={handleNext} />
    </div>
  );
};

export default SliderComp;
