import React from "react";
import styles from "../services.module.css";
import ServicesNav from "../ServicesNav";

const CanvasStretching = () => {
  return (
    <>
      <ServicesNav />
      <div className={styles.container}>
        <div className={styles.header}>
          <h2>Canvas Stretching</h2>
        </div>
        <div className={styles.imgContainer}>
          <img
            className={styles.imgCanvas}
            alt="canvas-service"
            src="../canvas_stratching_1.png"
          />
        </div>
        <div style={{ marginLeft: "2em", marginRight: "2em" }}>
          <p className={styles.desc}>
            In addition to our deep knowledge in quality archival framing,
            design, and restoration, we also offer superior service in canvas
            stretching to all the Bay Area. Whether you bring us Belgian lenin
            or duck canvas, with over 30 years of experience stretching an
            endless variety of material, we can assure that your art will be
            stretched with both a gentle touch and pinpoint accuracy.
          </p>

          <p className={styles.desc}>
            “The word canvas is derived from the Latin word “cannabis” and has
            existed as a fabric since ancient times. Canvas can be composed of
            hemp, flax or cotton. The flax plant is the source of linen. The
            fibers of linen are round and give the fabric and irregular texture
            and therefore a very natural and random look that is highly prized
            by artists. The natural presence of linseed oil in the plant
            preserves the fibers and keeps them flexible. Canvases composed of
            hemp and of linen are virtually indistinguishable from each other
            with the naked eye but since linen was the more costly of the two
            due to the difficulty of processing it, hemp was quite popular among
            artists for whom cost mattered until the end of the 18th century.
          </p>

          <p className={styles.desc}>
            The increasing use of canvas in the last few centuries has had a
            significant impact on painting as an art form. The portability of a
            painting executed on canvas freed painters to work in the comfort of
            their studios or even out of doors instead of at the final
            destination of the work of art, as with mural painting. Large
            canvasses were more easily transported and mounted than large wooden
            panels. In addition, the discontinuity caused by the need to paint
            around or over architectural structures within a room gave way to a
            powerful unity of theme provided by the rectangular shape of
            stretched canvas.” -Diana Blake
          </p>
          <div className={styles.imgContainer}>
            <img
              alt="craft-service"
              src="../canvastretch_2.png"
              className={styles.imgCanvas}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CanvasStretching;
