import React, { useState } from "react";
import { Layout, InfoContainer, Switch } from "../../components";
import styles from "./ContactUs.module.css";
import ReactGA from "react-ga";

const ContactUs = () => {
  ReactGA.pageview(window.location.pathname);

  //true -> OAKLAND
  //false -> BERKELEY
  const [checked, setChecked] = useState(false);

  const handleChange = (checked) => {
    setChecked(checked);
  };

  const info = {
    OAKLAND: {
      location:
        "Inside the FLAX Building 1501 Martin Luther King Jr Way (at 15th St.) Oakland CA, 94612",
      call: "(510) 944-4240",
      email: "artisticframingoak@gmail.com",
      hrsOfOperation: [
        "10am to 6pm",
        "10am to 6pm",
        "10am to 6pm",
        "10am to 6pm",
        "10am to 6pm",
      ],
    },
  };

  return (
    <Layout>
      <div className={styles.header}>
        <span>
          <a href="/" target="_blank">
            Home
          </a>
          |
          <a href="/" target="_blank" style={{ fontWeight: 600 }}>
            Contact Us
          </a>
        </span>
        <h2>Oakland</h2>
        <div className={styles.line} />
      </div>

      <div className={styles.content}>
        <InfoContainer data={info["OAKLAND"]} location={"OAKLAND"} />

        <div className={styles.mapContainer}>
          <a
            className={styles.card}
            href="https://cal.com/artisticframingoak/design-consultation"
            target="_blank"
          >
            <p style={{ fontSize: 20 }}>Design Consultation</p>
          </a>
          <a
            className={styles.card}
            href="https://cal.com/artisticframingoak/printing-consultation"
            target="_blank"
          >
            <p style={{ fontSize: 20 }}>Printing Consultation</p>
          </a>
          <a
            className={styles.card}
            href="https://cal.com/artisticframingoak/art-pickup"
            target="_blank"
          >
            <p style={{ fontSize: 20 }}>Order Pickup</p>
          </a>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3152.267596651468!2d-122.28019697310474!3d37.80720061499514!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808f80afe5f9e9d9%3A0x32b6160617a499d3!2s1501%20Martin%20Luther%20King%20Jr%20Way%2C%20Oakland%2C%20CA%2094612%2C%20USA!5e0!3m2!1sen!2sin!4v1673371219850!5m2!1sen!2sin"></iframe>
        </div>
      </div>
    </Layout>
  );
};

export default ContactUs;
