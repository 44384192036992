import React from "react";
import { Layout, CanvasStretchingServices } from "../../components";

const CanvasStretching = () => {
  return (
    <Layout>
      <CanvasStretchingServices />
    </Layout>
  );
};

export default CanvasStretching;
