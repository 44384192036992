import React from "react";
import styles from "./HeroSection.module.css";

const HeroSection = () => {
  return (
    <div className={styles.container}>
      <div className={styles.center}>
        <img
          className={styles.new_pic1}
          alt="top-left"
          src="../landing/pic4.png"
        />
        <div className={styles.new_left_container}>
          <img
            className={styles.new_pic2}
            alt="bottom-left"
            src="../landing/pic3.png"
          />
          <img
            className={styles.new_pic3}
            alt="top-right"
            src="../landing/pic1.png"
          />
        </div>
      </div>

      {/* <img
          className={styles.group11}
          alt="bar"
          src="../landing/group11.png"
        /> */}
      {/* <img
          className={styles.pic1}
          alt="top-right"
          src="../landing/pic1.png"
        />
        <img
          className={styles.pic3}
          alt="bottom-left"
          src="../landing/pic3.png"
        />
        <img
          className={styles.pic4}
          alt="bottom-right"
          src="../landing/pic4.png"
        />
        <img
          className={styles.group11}
          alt="bar"
          src="../landing/group11.png"
        /> */}
      <div className={styles.right_container}>
        <div className={styles.title}>WHO WE ARE</div>
        <div className={styles.text}>
          <p className={styles.text_para}>
            Our designs are based on what we learn about your taste and what you
            expect your piece to manifest and evoke once it hangs on your wall.
          </p>
        </div>
        <div className={styles.text2}>
          We go above and beyond the call of duty to deliver the highest quality
          of work.
        </div>
        <div>
          <div className={styles.button}>
            <a className={styles.button_a} href="/contact-us-berkeley">
              Schedule an Appointment in Berkeley
            </a>
          </div>
          <div className={styles.button}>
            <a className={styles.button_a} href="/contact-us-oakland">
              Schedule an Appointment in Oakland
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
