import React from "react";
import styles from "../services.module.css";
import ServicesNav from "../ServicesNav";

const Delivery = () => {
  return (
    <>
      <ServicesNav />
      <div className={styles.container}>
        <div className={styles.header}>
          <h2>Delivery and Installation</h2>
        </div>
        <span className={styles.heading}>
          <h2>PICK-UP, DELIVERY, & INSTALLATION</h2>
        </span>
        <div className={styles.imgContainer}>
          <img
            alt="canvas-service"
            className={styles.imgCanvas}
            src="../pickup_delivery_1.png"
          />
        </div>
        <div style={{ marginLeft: "2em", marginRight: "2em" }}>
          <p className={styles.desc}>
            In addition to quality preservation framing and design, we also
            offer a service that very few framing shops even attempt to tackle.
            Not only will we deliver the finalized piece to you but we will also
            pick the piece up from any location in the bay area. Installing any
            amount of your pieces will be on us when your order exceeds $500.
            Many customers bring us folded or rolled up pieces that turn out to
            be larger than they see fit in a standard vehicle. To remedy this
            situation, we can transfer any size piece safely and timely to all
            of our customers.
          </p>

          <div className={styles.imgContainer}>
            <img
              alt="craft-service"
              className={styles.imgCanvas}
              src="../pickup_delivery_2.png"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Delivery;
