import { Routes, Route, useNavigationType } from "react-router-dom";
import {
  Landing,
  ContactUs,
  Gallery,
  Printing,
  Craftmanship,
  CanvasStretching,
  Delivery,
  ContactOak,
  ContactBerkeley,
} from "./pages";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import ReactGA from "react-ga";

function App() {
  ReactGA.initialize("UA-192614868-2");
  const action = useNavigationType();

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/contact-us-oakland" element={<ContactOak />} />
        <Route path="/contact-us-berkeley" element={<ContactBerkeley />} />
        <Route path="/gallery" element={<Gallery />} />
        {/* <Route path="/services" element={<Printing />} /> */}
        <Route path="/services/craftmanship" element={<Craftmanship />} />
        <Route path="/services/delivery" element={<Delivery />} />
        <Route
          path="/services/canvas-stretching"
          element={<CanvasStretching />}
        />
        <Route path="/gallery/:category" element={<Gallery />} />
      </Routes>
      <ToastContainer />
    </>
  );
}
export default App;
