import React, { useState, useEffect } from "react";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import StorySection from "../StorySection";
import styles from "./GalleryView.module.css";
import { useParams } from "react-router-dom";
import ImageGrid from "./Grid";

const GalleryView = ({ data }) => {
  const category = {
    "Concert-Flyers": "Concert Flyers",
    Fillets: "Fillets",
    "Floating-Art": "Floating Art",
    "In-House-Prints": "In-House Prints",
    "Original-Art": "Original Art",
    "Photographs-Prints": "Photographs and Prints",
    "Shadow-Boxes": "Shadow Boxes",
    "Mat-Designs": "Specialty Mat Designs",
    "Sports-Icons": "Sports Icons",
    "Stacked-Frames": "Stacked Frames",
    "Textiles-Fabric-Art": "Textiles and Fabric Art",
    "Certificates-Documents": "Certificates and Documents",
  };

  const params = useParams();
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 1;
  const totalPages = Math.ceil(
    params?.category
      ? data.filter((item) => item.category === category[params.category])
          .length / itemsPerPage
      : data.length / itemsPerPage
  );
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    setIsAnimating(true);
    setTimeout(() => {
      setIsAnimating(false);
    }, 600);
  }, [currentPage]);

  const handlePrevClick = () => {
    setCurrentPage(currentPage - 1);
    window.scrollTo(0, 0);
  };

  const handleNextClick = () => {
    setCurrentPage(currentPage + 1);
    window.scrollTo(0, 0);
  };
  return (
    <div className={styles.book}>
      <div className={`${styles.page} ${isAnimating ? styles.animating : ""}`}>
        <div>
          {!params?.category ? (
            data
              .slice(
                (currentPage - 1) * itemsPerPage,
                currentPage * itemsPerPage
              )
              .map((item, index) => <StorySection key={index} story={item} />)
          ) : params?.category === "All" ? (
            <ImageGrid data={data} />
          ) : (
            data
              .filter((item) => item.category === category[params.category])
              .slice(
                (currentPage - 1) * itemsPerPage,
                currentPage * itemsPerPage
              )
              .map((item, index) => {
                return <StorySection key={index} story={item} />;
              })
          )}
        </div>
      </div>

      {params?.category === "All" ? null : (
        <div className={styles.paginations}>
          {currentPage > 1 && (
            <MdKeyboardArrowLeft
              onClick={handlePrevClick}
              size={24}
              color={"#26a48e"}
              style={{ cursor: "pointer" }}
            />
          )}
          <button>PAGE {currentPage}</button>
          {currentPage < totalPages && (
            <MdKeyboardArrowRight
              onClick={handleNextClick}
              size={24}
              color={"#26a48e"}
              style={{ cursor: "pointer" }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default GalleryView;
