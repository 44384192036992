import React, { useState } from "react";
import { Layout, InfoContainer, Switch } from "../../components";
import styles from "./ContactUs.module.css";
import ReactGA from "react-ga";

const ContactUs = () => {
  ReactGA.pageview(window.location.pathname);

  //true -> OAKLAND
  //false -> BERKELEY
  const [checked, setChecked] = useState(false);

  const handleChange = (checked) => {
    setChecked(checked);
  };

  const info = {
    BERKELEY: {
      location: "1678 Shattuck Avenue Berkeley, CA 94709",
      call: "(510) 665-5951",
      email: "artisticpictureframing@gmail.com",
      hrsOfOperation: [
        "10am to 6pm",
        "10am to 6pm",
        "10am to 6pm",
        "10am to 6pm",
        "10am to 6pm",
        "10am to 6pm",
        "10am to 6pm",
      ],
    },
    OAKLAND: {
      location:
        "Inside the FLAX Building 1501 Martin Luther King Jr Way (at 15th St.) Oakland CA, 94612",
      call: "(510) 944-4240",
      email: "artisticframingoak@gmail.com",
      hrsOfOperation: [
        "10am to 6pm",
        "10am to 6pm",
        "10am to 6pm",
        "10am to 6pm",
        "10am to 6pm",
      ],
    },
  };

  return (
    <Layout>
      <div className={styles.header}>
        <span>
          <a href="/" target="_blank">
            Home
          </a>
          |
          <a href="/" target="_blank" style={{ fontWeight: 600 }}>
            Contact Us
          </a>
        </span>
        <h2>Contact Us</h2>
        <div className={styles.line} />
      </div>

      <div className={styles.switch}>
        <span>
          <p>BERKELEY</p>
          <Switch checked={checked} handleChange={handleChange} />
          <p>OAKLAND</p>
        </span>
      </div>

      <div className={styles.content}>
        <InfoContainer
          data={info[checked ? "OAKLAND" : "BERKELEY"]}
          location={checked ? "OAKLAND" : "BERKELEY"}
        />
        {checked ? (
          <div className={styles.mapContainer}>
            <span style={{ marginTop: 0 }}>
              {/* <a
                href="https://calendly.com/artisticframingoak/oakland-pickup"
                target="_blank"
              >
                Schedule for Pickup
              </a> */}
              <a href="/contact-us-oakland" target="_blank">
                Schedule an Appointment in Oakland
              </a>
            </span>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3152.267596651468!2d-122.28019697310474!3d37.80720061499514!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808f80afe5f9e9d9%3A0x32b6160617a499d3!2s1501%20Martin%20Luther%20King%20Jr%20Way%2C%20Oakland%2C%20CA%2094612%2C%20USA!5e0!3m2!1sen!2sin!4v1673371219850!5m2!1sen!2sin"></iframe>
          </div>
        ) : (
          <div className={styles.mapContainer}>
            <span style={{ marginTop: 0 }}>
              {/* <a
                href="https://calendly.com/artisticpictureframing/berkeley-pickup"
                target="_blank"
              >
                Schedule for Pickup
              </a> */}
              <a href="/contact-us-berkeley" target="_blank">
                Schedule an Appointment in Berkeley
              </a>
            </span>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3149.28836761992!2d-122.27135978483602!3d37.876938379740935!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80857ea1ba14c5f7%3A0x72058387501766a1!2s1678%20Shattuck%20Ave.%2C%20Berkeley%2C%20CA%2094709%2C%20USA!5e0!3m2!1sen!2sin!4v1673369765511!5m2!1sen!2sin"></iframe>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default ContactUs;
