import React from "react";
import styles from "./Header.module.css";
import Logo from "../../Helper/Logo";

const Header = () => {
  return (
    <div className={styles.container}>
      <Logo />
      <h1 className={styles.title}>ARTISTIC PICTURE FRAMING</h1>
      <div></div>
    </div>
  );
};

export default Header;
