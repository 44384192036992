import React, { useState } from "react";
import styles from "./GalleryView.module.css";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import { AiFillCloseCircle } from "react-icons/ai";
import { Markup } from "interweave";

const ImageGrid = ({ data }) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const [gridPage, setGridPage] = useState(1);

  const onOpen = (idx) => {
    setSelected(idx);
    setOpen(true);
  };

  const Modal = () => {
    return (
      <div className={styles.modal}>
        <div className={styles.close}>
          <AiFillCloseCircle
            onClick={() => setOpen(false)}
            size={26}
            color={"#26a48e"}
            style={{ cursor: "pointer" }}
          />
        </div>

        <div className={styles.imgCont}>
          <MdKeyboardArrowLeft
            onClick={() => {
              if (selected > 0) {
                setSelected(selected - 1);
              }
            }}
            size={26}
            color={"#26a48e"}
            style={{ cursor: "pointer" }}
          />
          <img src={data[selected].imageUrl[0]} />
          <MdKeyboardArrowRight
            onClick={() => {
              if (selected < Math.ceil(data.length)) {
                setSelected(selected + 1);
              }
            }}
            size={26}
            color={"#26a48e"}
            style={{ cursor: "pointer" }}
          />
        </div>

        <div className={styles.modalBody}>
          <h1>{data[selected].title}</h1>
          <Markup content={data[selected].description} />
        </div>
      </div>
    );
  };
  const handlePrevClick = () => {
    setGridPage(gridPage - 1);
    window.scrollTo(0, 0);
  };

  const handleNextClick = () => {
    setGridPage(gridPage + 1);
    window.scrollTo(0, 0);
  };

  const itemsPerPage = 24;
  const totalPages = Math.ceil(data.length / itemsPerPage);
  return (
    <div style={{ marginLeft: 10 }}>
      {open && <Modal />}
      <div className={styles.imgGrid}>
        {data
          .slice((gridPage - 1) * itemsPerPage, gridPage * itemsPerPage)
          .map((item, index) => (
            <img onClick={() => onOpen(index)} src={item.imageUrl[0]} />
          ))}
      </div>
      <div className={styles.paginations}>
        {gridPage > 1 && (
          <MdKeyboardArrowLeft
            onClick={handlePrevClick}
            size={24}
            color={"#26a48e"}
            style={{ cursor: "pointer" }}
          />
        )}
        <button>PAGE {gridPage}</button>
        {gridPage < totalPages && (
          <MdKeyboardArrowRight
            onClick={handleNextClick}
            size={24}
            color={"#26a48e"}
            style={{ cursor: "pointer" }}
          />
        )}
      </div>
    </div>
  );
};

export default ImageGrid;
