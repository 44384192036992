import React from "react";
import { Layout, DeliveryServices } from "../../components";
import ReactGA from "react-ga";

const Delivery = () => {
  ReactGA.pageview(window.location.pathname);

  return (
    <Layout>
      <DeliveryServices />
    </Layout>
  );
};

export default Delivery;
