import styles from "./Landing.module.css";
import {
  HeroSection,
  OurServices,
  Layout,
  EmailUpdate,
  Carousel,
  CustomFarming,
} from "../../components";
import ReactGA from "react-ga";

const Landing = () => {
  ReactGA.pageview(window.location.pathname);
  return (
    <Layout>
      <HeroSection />
      {/* <Carousel /> */}
      <CustomFarming />
      <OurServices />
      <EmailUpdate />
    </Layout>
  );
};

export default Landing;
