import React, { useState, useEffect } from "react";
import styles from "./Navbar.module.css";

const Navbar = () => {
  const [path, setPath] = useState("/");

  useEffect(() => {
    setPath(window.location.pathname);
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.items}>
        <a className={styles.appBtn} href="contact-us-berkeley">
          Schedule an Appointment in Berkeley
        </a>
        <a className={styles.appBtn} href="/contact-us-oakland">
          Schedule an Appointment in Oakland
        </a>
        <div className={styles.wrapper}>
          <a
            className={styles.btn}
            href="/gallery"
            style={{
              fontWeight: path === "/gallery" ? "600" : "",
              textDecoration: path === "/gallery" ? "underline" : "none",
            }}
          >
            Gallery
          </a>
        </div>
        <div className={styles.wrapper}>
          <a
            className={styles.btn}
            href="/services/craftmanship"
            style={{
              fontWeight: path === "/services/craftmanship" ? "600" : "",
              textDecoration:
                path === "/services/craftmanship" ? "underline" : "none",
            }}
          >
            Craftsman Framing
          </a>
        </div>
        <div className={styles.wrapper}>
          <a
            className={styles.btn}
            href="/contact-us"
            style={{
              fontWeight: path === "/contact-us" ? "600" : "",
              textDecoration: path === "/contact-us" ? "underline" : "none",
            }}
          >
            Contact Us
          </a>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
