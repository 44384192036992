import React, { useState, useEffect } from "react";
import styles from "./services.module.css";

const ServicesNav = () => {
  const [path, setPath] = useState("/");

  useEffect(() => {
    setPath(window.location.pathname);
  }, []);

  return (
    <div className={styles.nav}>
      <span>
        <a
          href="/services/craftmanship"
          style={{
            fontWeight: path === "/services/craftmanship" ? "600" : "",
            border:
              path === "/services/craftmanship" ? "1px solid #000" : "none",
          }}
        >
          Craftsman Framing
        </a>

        <a
          href="/services/canvas-stretching"
          style={{
            fontWeight: path === "/services/canvas-stretching" ? "600" : "",
            border:
              path === "/services/canvas-stretching"
                ? "1px solid #000"
                : "none",
          }}
        >
          Canvas Stretching
        </a>

        <a
          href="/services/delivery"
          style={{
            fontWeight: path === "/services/delivery" ? "600" : "",
            border: path === "/services/delivery" ? "1px solid #000" : "none",
          }}
        >
          Delivery and Installation
        </a>
      </span>
      <div className={styles.line} />
    </div>
  );
};

export default ServicesNav;
