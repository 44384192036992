import React, { useState } from "react";
import styles from "./EmailUpdates.module.css";
import { IoIosArrowForward } from "react-icons/io";
import { addEmail } from "../../../services/API";
import { toast } from "react-toastify";
import { validate } from "react-email-validator";

const EmailUpdate = () => {
  const [email, setEmail] = useState("");

  const addEmailHanlder = async () => {
    if (validate(email)) {
      const res = await addEmail(email);
      if (res.success) {
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    } else {
      toast.error("Invalid Email");
    }
  };
  return (
    <div className={styles.container}>
      <img className={styles.img} alt="" src="../rectangle-90@2x.png" />
      <div className={styles.box}>
        <p className={styles.heading}>
          Get all the updates and exclusive offers
        </p>
        <span className={styles.textField}>
          <input
            type="text"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <span onClick={addEmailHanlder}>
            <IoIosArrowForward size={16} />
          </span>
        </span>
      </div>
    </div>
  );
};

export default EmailUpdate;
