import React from "react";
import styles from "./Footer.module.css";
import {
  AiFillFacebook,
  AiOutlineInstagram,
  AiFillGoogleCircle,
} from "react-icons/ai";
import Logo from "../../Helper/Logo";
import { FaYelp } from "react-icons/fa";
import { BsGoogle } from "react-icons/bs";
const Footer = () => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.box}>
          <div style={{ marginLeft: 20 }}>
            <a className={styles.link} href="/">
              <div className={styles.Logocontainer}>
                <p className={styles.Logotitle}>A</p>
              </div>
            </a>
          </div>
          <div>
            <div className={styles.links}>
              <a>About</a>
              <a href="/contact-us" target="_blank">
                Contact
              </a>
              <a>Blog</a>
              <a
                href="https://squareup.com/gift/8P08MHZ7D3FE1/order"
                target="_blank"
              >
                Gift Card
              </a>
              <br />
            </div>
            <div
              className={styles.links}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: 30,
                }}
              >
                <FaYelp color="white" size={20} />

                <a
                  href="https://www.yelp.com/biz/artistic-picture-framing-berkeley-3"
                  target="_blank"
                  style={{ margin: 0, marginLeft: 10 }}
                >
                  Reviews on Yelp
                </a>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <BsGoogle color="white" size={20} />
                <a
                  href="https://www.google.com/maps/place/Artistic+Picture+Framing/@37.8769384,-122.271746,17z/data=!4m8!3m7!1s0x80857e9ef8239129:0x8a92c407f27c1cff!8m2!3d37.8769384!4d-122.2691711!9m1!1b1!16s%2Fg%2F1vcl3_2f"
                  target="_blank"
                  style={{ margin: 0, marginLeft: 10 }}
                >
                  Reviews on Google
                </a>
              </div>

              <br />
            </div>
          </div>
          <div className={styles.socials}>
            <p>FOLLOW US</p>
            <span>
              <a
                href="https://www.facebook.com/artisticframingexpert/"
                target="_blank"
              >
                <AiFillFacebook color="white" size={30} />
              </a>
              <a
                href="https://www.instagram.com/artisticpictureframing/"
                target="_blank"
              >
                <AiOutlineInstagram color="white" size={30} />
              </a>
            </span>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <Logo />
        <h1>ARTISTIC PICTURE FRAMING</h1>
        <p className={styles.text}>
          © All rights reserved by Artistic Picture Framing LLC.
        </p>
      </div>
    </>
  );
};

export default Footer;
