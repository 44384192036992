import React from "react";
import styles from "./OurServices.module.css";
import SliderComp from "./SliderComp";
import { useMediaQuery } from "react-responsive";

const OurServices = () => {
  const isScreen1 = useMediaQuery({ query: "(max-width: 1300px)" });
  const isScreen2 = useMediaQuery({ query: "(max-width: 1024px)" });
  const isScreen3 = useMediaQuery({ query: "(max-width: 800px)" });

  const applyHeight = () => {
    let height = 90;
    if (isScreen1) {
      height = 45;
    }
    if (isScreen2) {
      height = 50;
    }
    if (isScreen3) {
      height = 55;
    }
    return height;
  };

  const reviews = [
    {
      name: "Chelsey 'Smiley' C",
      location: "Manhattan, NY",
      desc: (
        <span>
          "There's no doubt that Rodolfo is a man of experience with over 30
          years of framing under his belt. He's an artist with exquisite taste -
          going above and beyond the call of duty to deliver on the highest
          quality work. <br /> My friend and I came in to obtain a temporary
          frame, and despite knowing we were going to replace it, Rodolfo still
          goes great lengths to do the job properly, sparing no expense. The
          finished product is absolutely exquisite. <br /> Should I ever need
          any type of framing job in the future, Rodolfo is the man"
        </span>
      ),
      image: "../reviews/chelsey_1.jpg",
    },

    {
      name: "Gabrielle A.",
      desc: "This is by far one of the best frame shops I have ever worked with! I am a working artist and need pieces framed all the time. I recently had a large show at the SFMOMA Artists Gallery and needed over 30 pieces framed at once. I can easily say that the work Rodolfo did at Artistic Picture Framing was beyond my expectations.  Each piece was completed with museum quality and, as a whole, the final show looked exceptional. Rodolfo (the owner) is a master at his craft, very professional and works with the utmost integrity. He was always available, was prompt in returning my calls, went out of his way to help and took personal pride in making my show a success. He was helpful in choosing the frames for my work, he finished everything on (or before!) schedule and, best of all, BEAT the pricing (by a long shot!) of every other frame store I called (I interviewed 6 frames shops throughout the San Francisco & Berkeley areas before choosing Artistic Picture Framing). Obviously, I highly recommend this business. Thanks Rodolfo!!",
      location: "Makawao, HI",
      image: "../reviews/review_2.jpg",
    },
    {
      desc: "Excellent service and delivery! Rodolfo framed a family portrait as a gift for my Mom.   Rodolfo asked some questions about my Mom's sense of style and used that to come up with styles that he thought she would like. We worked together making decisions to keep the project within my budget and in under a week I had a beautiful framed family portrait! On top of this, Rodolfo prepared the portrait for its trip across the country and the portrait made it home intact.  My Mom was so happy and she continues to get compliments.  Works for me:)",
      name: "Ajitha N.",
      location: "Oakland, CA",
      image: "../reviews/review_3.jpg",
    },
    {
      name: "Cassandra C.",
      location: "Oakland, CA",
      image: "../reviews/review_4.jpg",
      desc: (
        <span>
          "In an era where pre-fab and cookie-cutter goods from big box
          retailers abound, thank goodness that true craftsmen still exist.
          <br />
          If it isn't important to you, buy a nice, premade frame at a retailer
          and be done with it. This is NOT where you get your posters or movie
          tickets framed- unless they are really precious to you and you want to
          present them that way.
          <br />
          This is where you bring things that are SPECIAL. It might be original
          art, the diploma or license that is the heart of your profession, or
          the the awards you are most proud of achieving. This is where you
          bring things you love and they are treated with reverence and
          respect."
        </span>
      ),
    },
  ];

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <h2>Our</h2>
          <h2>Services</h2>
        </div>
        <div className={styles.content}>
          <div className={styles.secondaryContent_1}>
            <div className={styles.fields}>
              <ul>
                <li>Design Consultation</li>
                <li>Canvas Stretching</li>
                <li>Canvas & Paper Printing</li>
                <li>Metallic Paper Printing</li>
                <li>Digital Photorestoration</li>
                <li>Floating Frames</li>
                <li>3D Shadow Boxing</li>
              </ul>
            </div>
            <div className={styles.fields}>
              <ul>
                <li>Natural & Burl Woods</li>
                <li>Close End Corners</li>
                <li>Wrap Around Fabrics</li>
                <li>Multiple Window Mats</li>
                <li>Certificate Framing</li>
                <li>Wedding & Event Framing</li>
                <li>Needle Point & Cross Stitch Framing</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <SliderComp reviews={reviews} />
    </>
  );
};

export default OurServices;
