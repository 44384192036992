import React from "react";
import styles from "../services.module.css";
import ServicesNav from "../ServicesNav";

const Craftmanship = () => {
  return (
    <>
      <ServicesNav />
      <div className={styles.container}>
        <div className={styles.header}>
          <h2>Craftsman Framing</h2>
        </div>
        <span className={styles.heading}>
          <h2>EXPERT CRAFTSMANSHIP</h2>
        </span>
        <p className={styles.desc}>
          We are craftsman frame makers widely known as the 'design specialists'
          of the bay area. While using all preservation and quality materials,
          we can adapt to any customers budget and style. Let us know your price
          range and we can create an everlasting piece, accordingly. We
          specialize in...
        </p>
        <div className={styles.imgContainer}>
          <img
            alt="craft-service"
            className={styles.imgCraftman}
            src="../craft_1.png"
          />
        </div>
        <div style={{ display: "flex" }}>
          <ul>
            <li>Affordable preservation framing and design</li>
            <li>Photo prints</li>
            <li>Sports Jersey framing and design</li>
            <li>Wedding and Diploma framing</li>
            <li>Oil Paintings</li>
            <li>3D object mounts</li>
            <li>Canvas Stretching</li>
            <li>Oversize or small scale stretches</li>
            <li>Crossbar reinforcements for larger stretches</li>
            <li>Framing, preserving, and transporting</li>
          </ul>
          <ul>
            <li>Artwork Restoration</li>
            <li>Fine art canvasses</li>
            <li>Scratched or damaged moulding</li>
            <li>Oil painting dirt, or discoloration</li>
            <li>Quality Gold leafing</li>
            <li>Corporate and/or Sports designs</li>
            <li>Championship design layouts</li>
            <li>Corporate interior design layouts</li>
            <li>Price packages for business or team framing</li>
          </ul>
        </div>
        <span className={styles.heading}>
          <h2>MUSEUM GLAZING</h2>
        </span>
        <p className={styles.desc}>
          We offer the industries top anti-reflection picture framing glass with
          Conservation Grade UV Protection as the best glazing option available
          for art, photographs and other important personal keepsakes. Along
          with its nearly invisible finish, it effectively blocks up to 99% of
          harmful indoor and outdoor UV light rays so framed pieces remain
          clearer and brighter for longer.
        </p>
        <div className={styles.imgContainer}>
          <img
            alt="craft-service"
            className={styles.imgCraftman}
            src="../craft_2.png"
          />
        </div>
        <p className={styles.desc}>
          Some of the top benefits for using museum glass are...
        </p>
        <ul>
          <li>
            Reduces reflection by over 85% (to less than 1% of total light), the
            lowest possible reflection rating available with UV protection
          </li>
          <li>
            Achieves over 97% light transmission to enhance colors, brightness
            and contrast levels
          </li>
          <li>Blocks up to 99% of UV light rays</li>
          <li>
            Meets ISO 18902 and passes ISO 18916, by providing at least 97% UV
            protection
          </li>
          <li>
            Does not degrade or delaminate over time. Tru Vue uses a proprietary
            inorganic, silica-based UV blocking coating, which is “baked” into
            the glass producing a permanently bonded coating.
          </li>
          <li>2.5mm glass substrate</li>
        </ul>
      </div>
    </>
  );
};

export default Craftmanship;
