import React, { useState } from "react";
import { Layout, InfoContainer, Switch } from "../../components";
import styles from "./ContactUs.module.css";
import ReactGA from "react-ga";

const ContactUs = () => {
  ReactGA.pageview(window.location.pathname);

  //true -> OAKLAND
  //false -> BERKELEY
  const [checked, setChecked] = useState(false);

  const handleChange = (checked) => {
    setChecked(checked);
  };

  const info = {
    BERKELEY: {
      location: "1678 Shattuck Avenue Berkeley, CA 94709",
      call: "(510) 665-5951",
      email: "artisticpictureframing@gmail.com",
      hrsOfOperation: [
        "10am to 6pm",
        "10am to 6pm",
        "10am to 6pm",
        "10am to 6pm",
        "10am to 6pm",
        "10am to 6pm",
        "10am to 6pm",
      ],
    },
  };

  return (
    <Layout>
      <div className={styles.header}>
        <span>
          <a href="/" target="_blank">
            Home
          </a>
          |
          <a href="/" target="_blank" style={{ fontWeight: 600 }}>
            Contact Us
          </a>
        </span>
        <h2>BERKELEY</h2>
        <div className={styles.line} />
      </div>

      <div className={styles.content}>
        <InfoContainer data={info["BERKELEY"]} location={"BERKELEY"} />

        <div className={styles.mapContainer}>
          <a
            className={styles.card}
            href="https://cal.com/artisticpictureframing/design-consultation"
            target="_blank"
          >
            <p style={{ fontSize: 20, lineHeight: 1 }}>
              Schedule for Design Consultation
            </p>
            {/* <p>45 Mins</p> */}
          </a>
          <a
            className={styles.card}
            href="https://cal.com/artisticpictureframing/15-min-order-pickup"
            target="_blank"
          >
            <p style={{ fontSize: 20 }}>Order Pickup</p>
          </a>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3149.288367952594!2d-122.27174602463687!3d37.876938371959795!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80857ea1ba14c5f7%3A0x72058387501766a1!2s1678%20Shattuck%20Ave.%2C%20Berkeley%2C%20CA%2094709%2C%20USA!5e0!3m2!1sen!2sin!4v1683657107503!5m2!1sen!2sin"
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </Layout>
  );
};

export default ContactUs;
