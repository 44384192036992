import React from "react";
import styles from "./StorySection.module.css";
import { Markup } from "interweave";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const StorySection = ({ story }) => {
  const marking = ["A", "B", "C", "D", "E", "F"];
  // const [tempStory, setTempStory] = useState([]);

  // useEffect(() => {
  //   setTempStory([
  //     story.imageUrl[0],
  //     story.imageUrl[1],
  //     story.imageUrl[2],
  //     story.imageUrl[2],
  //   ]);
  //   console.log(tempStory);
  // }, [story]);
  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <div className={styles.imgGrid}>
          {story.imageUrl.map((image, index) => (
            <div>
              <Zoom>
                <img alt="image" className={styles.img} src={image} />
              </Zoom>
              <p className={styles.text}>{marking[index]}</p>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.title}>{story.title}</div>
        <div className={styles.outerBox}>
          <div className={styles.content}>
            <Markup content={story.description} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StorySection;
