import React from "react";
import styles from "./CustomFarming.module.css";

//7 8 9 10

const CustomFarming = () => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.title}>
            <span>
              <h2>Custom</h2>
              <h2>Framing</h2>
            </span>
          </div>
          <p></p>
        </div>
        <div className={styles.content}>
          <div className={styles.row_1}>
            <div className={styles.frame_1}>
              <div className={styles.text}>
                <p className={styles.field}>Matting</p>
                <p className={styles.value_2}>
                  The Mat in custom framing serves more than just a pretty
                  border.
                </p>
                <p className={styles.value}>
                  The mat provides important practical functions, and it has a
                  visual purpose as well.
                </p>
              </div>
              <div>
                <img src="../ff4b403430c241e98f63d745b12b4d97-1@2x.png" />
              </div>
              <div className={styles.arrow_1}>
                <img src="../vector-16.svg" alt="vector" />
              </div>
            </div>
            <div className={styles.frame_2}>
              <div className={styles.child}>
                <div style={{ display: "flex" }}>
                  <div className={styles.section_1}>
                    <img src="../b0cf5b2147254063adb832b8ebfab821-1@2x.png" />
                  </div>
                  <div className={styles.section_2}>
                    <img src="../3abef55e86ff46cc81095a362ff023ea-1@2x.png" />
                    <span>
                      <p style={{ marginLeft: 18 }} className={styles.field}>
                        Mounting
                      </p>
                      <p style={{ marginLeft: 18 }} className={styles.value}>
                        It is the method used for holding your artwork in place
                        within a frame.
                      </p>
                    </span>
                  </div>
                </div>
                <div className={styles.arrow_2}>
                  <img
                    src="../vector-18.svg"
                    alt="vector"
                    className={styles.arrow}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={styles.row_1}>
            <div className={styles.frame_4}>
              <div className={styles.child}>
                <div className={styles.shift_1}>
                  <p className={styles.field}>Cut the moulding</p>
                  <p className={styles.value_2}>
                    It's four pieces of base cap molding, cut at a 45-degree
                    angle on the ends, to make a picture frame.
                  </p>
                </div>
                <img src="../img0481-1@2x.png" />
              </div>
              <div style={{ display: "flex" }}>
                <div className={styles.arrow_4}>
                  <img src="../vector-20.svg" alt="vector" />
                </div>
                <div className={styles.frame_4b}>
                  <p style={{ marginLeft: 10 }} className={styles.field}>
                    Join
                  </p>
                  <p
                    style={{ width: "100%", marginLeft: 12 }}
                    className={styles.value}
                  >
                    Join the picture frame together
                  </p>
                  <div className={styles.imgSection}>
                    <img
                      className={styles.img_1}
                      src="../a2e966a7c1f04103947acae4a7811982-1@2x.png"
                    />
                    <img
                      className={styles.img_2}
                      src="../95b3650a1b254c38b9d9ed10b091da2a-1@2x.png"
                    />
                  </div>

                  <div className={styles.arrow_5}>
                    <img src="../vector-22.svg" alt="vector" height={300} />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.frame_3}>
              <div className={styles.section}>
                <span>
                  <div style={{ textAlign: "right" }}>
                    <p className={styles.field}>Cutting</p>
                    <p className={styles.field}>the glass</p>
                  </div>
                  <p
                    style={{
                      textAlign: "right",
                    }}
                    className={styles.value}
                  >
                    It is the art or process of cutting glass
                  </p>
                </span>

                <img src="../img0474-1@2x.png" className={styles.img} />
              </div>
              <div className={styles.arrow_3}>
                <img src="../vector-19.svg" alt="vector" />
              </div>
            </div>
          </div>

          <div>
            <div className={styles.frame_5}>
              <p className={styles.field}>Finishing</p>
              <img
                className={styles.img_1}
                src="../c918ec9edf824afcaa8ed45968fb6058-1@2x.png"
              />
              <div style={{ display: "flex" }}>
                <img
                  className={styles.img_2}
                  src="../3467ff5740174c4789a06354bca19509-1@2x.png"
                />
                <div className={styles.arrow_6}>
                  <img src="../vector-21.svg" alt="vector" />
                </div>
                <div className={styles.frame_6}>
                  <p className={styles.field}>Fitting</p>
                  <p className={styles.value} style={{ marginBottom: "1em" }}>
                    The foam core is fitted using the Framer's point gun
                  </p>
                  <img src="../886ea5323130452c89ca8541675288f9-1@2x.png" />
                </div>
              </div>
            </div>
          </div>
          {/* <div className={styles.vector_container}>
            <img
              src="../assets/orange_vector_1.svg"
              alt="vector"
              className={styles.vector_1}
            />
            <img
              src="../assets/green_vector_1.svg"
              alt="vector"
              className={styles.vector_2}
            />
            <img
              src="../assets/green_vector_2.svg"
              alt="vector"
              className={styles.vector_3}
            />
            <img
              src="../assets/orange_vector_2.svg"
              alt="vector"
              className={styles.vector_4}
            />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default CustomFarming;
