import React, { useState, useEffect } from "react";
import styles from "./Gallery.module.css";
import { Layout, GalleryView } from "../../components";
import { getGallery } from "../../services/API";
import ReactGA from "react-ga";

const Gallery = () => {
  const [gallery, setGallery] = useState([]);
  ReactGA.pageview(window.location.pathname);

  const setupGallery = async () => {
    const { data } = await getGallery();
    setGallery(data);
  };

  useEffect(() => {
    setupGallery();
  }, []);

  return (
    <Layout>
      <div className={styles.header}>
        <span>
          <a href="/">Home</a>|
          <a href="/" style={{ fontWeight: 600 }}>
            Gallery
          </a>
        </span>
        <h2>All Gallery</h2>
        <div className={styles.line} />
      </div>
      <div className={styles.body}>
        <div className={styles.options}>
          <a href="/gallery/All">All</a>
          <a href="/gallery/Canvas">Canvas</a>
          <a href="/gallery/Certificates-Documents">
            Certificates and Documents
          </a>
          <a href="/gallery/Concert-Flyers">Concert Flyers </a>
          <a href="/gallery/Fillets">Fillets </a>
          <a href="/gallery/Floating-Art">Floating Art</a>
          <a href="/gallery/In-House-Prints">In-House Prints </a>
          <a href="/gallery/Original-Art">Original Art </a>
          <a href="/gallery/Photographs-Prints">Photographs and Prints</a>
          <a href="/gallery/Shadow-Boxes">Shadow Boxes</a>
          <a href="/gallery/Mat-Designs">Specialty Mat Designs </a>
          <a href="/gallery/Sports-Icons">Sports Icons </a>
          <a href="/gallery/Stacked-Frames">Stacked Frames </a>
          <a href="/gallery/Textiles-Fabric-Art">Textiles and Fabric Art</a>
        </div>

        <GalleryView data={gallery} />
      </div>
    </Layout>
  );
};

export default Gallery;
